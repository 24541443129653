const Utilities = {
  install: function (Vue, options) {
    Object.defineProperty(Vue.prototype, '$utilities', {
      get () {
        return {
          publicPath: (path) => {
            try {
              if (path) {
                if (path.charAt(0) === '/') {
                  return `https://umedia.517uc.upcloudobjects.com${path}`
                } else {
                  return `https://umedia.517uc.upcloudobjects.com/${path}`
                }
              }
              return null
            } catch (e) {
              return null
            }
          },
          assets: (path) => {
            const baseUrl = this.$store.state.general.info.api_url
            if (baseUrl) {
              return `${baseUrl}/${path}`
            }
            return null
          }
        }
      }
    })
  }
}
export default Utilities
